.my-input::placeholder {
  color: black;
  font-size: 16px;
}

.login-input {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-top: 0px;
  color: black;
}

.eyeIcon {
  position: relative;
  background-color: black;
}
.eyeIcon-inner {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  z-index: 100;
}
