.text-center {
  text-align: center;
}
@media screen and (max-width: 899px) {
  .blogs-paddings {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
  }
}

@media screen and (min-width: 900px) {
  .blogs-paddings {
    padding-left: 137px;
    padding-right: 137px;
    padding-top: 137px;
  }
}

.add-line-button {
  background-color: #001529 !important;
  color: white !important;
  height: 50px;
  font-weight: bold !important;
  font-size: 20px;
}

.title_heading {
  margin-bottom: 10px;
}

.input-field-class {
  width: 100% !important;
}
.container-div {
  overflow-y: auto;
}
