.text-center {
    text-align: center;
  }
@media screen and (max-width: 899px) {

    .blogs-paddings {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
    }
}

@media screen and (min-width: 900px) {

    .blogs-paddings {
        padding-left: 137px;
        padding-right: 137px;
        padding-top: 137px;
    }
}