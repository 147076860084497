.password-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh !important;
  flex-direction: column;
}

.input-styling {
  width: 400px !important;
  height: 56px !important;
  border: 1px solid black !important;
  border-radius: 0px !important;
}

.submit-button {
  background-color: black !important;
  color: white !important;
  height: 56px !important;
  width: 400px !important;
  border-radius: 0px !important;
  border: none !important;
}
.heading {
  height: 100px !important;
}
.subHeading {
  font-size: 18px;
  font-weight: normal !important;
}
