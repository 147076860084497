
.ant-select-single{
    height:50px !important;
}

.ant-btn.ant-btn-sm{
    border-color: blue !important;
    color:black !important;
}                          

