 @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/FigtreeBold.ttf"); font-family : "Figtree"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/FigtreeBold.ttf"); font-family : "Figtree"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/RedHatMonoRomanMedium.ttf"); font-family : "Red Hat Mono"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/SpaceMonoRegular.ttf"); font-family : "Space Mono"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RedHatMonoRomanRegular.ttf"); font-family : "Red Hat Mono"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/FigtreeLight.ttf"); font-family : "Figtree"; font-weight : 300;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/RedHatMonoRomanLight.ttf"); font-family : "Red Hat Mono"; font-weight : 300;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/FigtreeBlack.ttf"); font-family : "Figtree"; font-weight : 900;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/FigtreeMedium.ttf"); font-family : "Figtree"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/SpaceMonoBold.ttf"); font-family : "Space Mono"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/RedHatMonoRomanSemiBold.ttf"); font-family : "Red Hat Mono"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("../assets/fonts/FigtreeSemiBold.ttf"); font-family : "Figtree"; font-weight : 600;  } 