/** @format */

.button-hover:hover {
  background-color: #001529;
  cursor: pointer;
  color: white;
}

.remove-paddings {
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 24px;
  border: solid 1px #001529;
  background-color: white;
}

@media (min-width: 900px) and (max-width: 920px) {
  .remove-paddings {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #001529;
    background-color: white;
  }
}